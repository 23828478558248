// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/Users/jvehikite/react/personal/calvano-site-gatsby/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-project-template-js": () => import("/Users/jvehikite/react/personal/calvano-site-gatsby/src/templates/projectTemplate.js" /* webpackChunkName: "component---src-templates-project-template-js" */),
  "component---src-templates-press-post-template-js": () => import("/Users/jvehikite/react/personal/calvano-site-gatsby/src/templates/pressPostTemplate.js" /* webpackChunkName: "component---src-templates-press-post-template-js" */),
  "component---src-templates-team-member-template-js": () => import("/Users/jvehikite/react/personal/calvano-site-gatsby/src/templates/teamMemberTemplate.js" /* webpackChunkName: "component---src-templates-team-member-template-js" */),
  "component---src-pages-index-js": () => import("/Users/jvehikite/react/personal/calvano-site-gatsby/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portfolio-js": () => import("/Users/jvehikite/react/personal/calvano-site-gatsby/src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-press-js": () => import("/Users/jvehikite/react/personal/calvano-site-gatsby/src/pages/press.js" /* webpackChunkName: "component---src-pages-press-js" */),
  "component---src-pages-team-js": () => import("/Users/jvehikite/react/personal/calvano-site-gatsby/src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-thanks-js": () => import("/Users/jvehikite/react/personal/calvano-site-gatsby/src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/Users/jvehikite/react/personal/calvano-site-gatsby/.cache/data.json")

