import { anchorate } from 'anchorate'

export default () => {
  anchorate({
    scroller: function(element) {
      if (!element) return false
      element.scrollIntoView({ behavior: 'smooth' })
      console.log(element)
      return true
    },
  })
}
